<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Criar novo Aluno"
      :enableClose="false"
    >
      <form
        class="block__form"
        @submit.prevent="salvar()"
      >
        <div v-if="showInstituicao" class="form-group">
          <label for="instituicao">Instituição</label>
          <select
            id="instituicao"
            v-model="item.instituicaoSelecionada"
            class="form-control"
            @change="onChangeInstituicao()"
          >
            <option
              v-for="instituicao in instituicoes"
              :key="instituicao.id"
              :value="instituicao.id"
            >
              {{ instituicao.nome }}
            </option>
          </select>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="ano">Ano</label>
              <select
                id="ano"
                v-model="item.anoSelecionado"
                class="form-control"
                :disabled="anos.length <= 0"
                @change="onChangeAno()"
              >
                <option v-for="ano in anos" :key="ano.id" :value="ano.id">
                  {{ ano.titulo }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="turma">Turma</label>
              <select
                id="turma"
                v-model="item.turmaSelecionada"
                class="form-control"
                :disabled="turmas.length <= 0"
              >
                <option v-for="turma in turmas" :key="turma.id" :value="turma.id">
                  {{ turma.titulo }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input
                id="nome"
                v-model="item.nome"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="item.email"
                type="email"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="telefone">Telefone do Responsável</label>
              <the-mask
                id="telefone"
                v-model="item.telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="senha">Senha de Acesso</label>
              <input
                id="senha"
                v-model="item.senha"
                type="password"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-6">
            <div class="form-group">
              <label for="codigo_identificacao">Código de identificação</label>
              <input
                id="codigo_identificacao"
                v-model="item.codigoIdentificacao"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <hr class="full-hr" />
        <div class="row">
          <div class="col-sm-12">
            <div class="float-right">
              <button
                type="submit"
                class="btn btn-classic"
                :disabled="!formIsValid"
              >
                Salvar
              </button>
              <button
                type="button"
                id="salvarAluno"
                class="btn btn-secondary m-2"
                @click="cancel()"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Alunos</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Alunos
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-7">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-5 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        id="novoAluno"
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>

                    <li class="list-inline-item">
                      <button
                        id="lixeiraAlunos"
                        class="btn btn-outline"
                        @click.prevent="trashRoute"
                      >
                        LIXEIRA
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Turma</th>
                    <th scope="col">Ano</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Código de identificação</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id">
                    <td class="text-left pl-4" v-html="item.turma.titulo" />
                    <td
                      class="text-left pl-4"
                      v-html="item.turma.anoEnsino.titulo"
                    />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.nome)"
                    />

                    <td class="text-left pl-4" v-html="item.usuario.email" />
                    <td class="text-left pl-4" v-html="item.codigoIdentificacao" />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="editar(item)"
                        id="editarAluno"
                      >
                        <i class="far fa-edit" />
                      </button>
                      <button
                        id="deletarAluno"
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
import store from "@/store";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      showLeftMenu: true,
      showModalCreate: false,
      showInstituicao: true,
      id: null,
      edit: false,
      index: -1,
      turmaSelecionadaEdit: 0,
      item: {
        id: null,
        nome: "",
        email: "",
        telefone: "",
        senha: "",
        turma: null,
        instituicaoSelecionada: "",
        anoSelecionado: "",
        turmaSelecionada: "",
        codigoIdentificacao: "",
      },
      items: [],
      filter: "",
      instituicoes: [],
      anos: [],
      turmas: [],
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        const textAno = row.turma.anoEnsino.titulo.toLowerCase();
        if (textAno) {
          return text.includes(searchTerm) || textAno.includes(searchTerm);
        } else {
          return text.includes(searchTerm);
        }
      });
    },
    formIsValid() {
      if (
        !this.item.nome ||
        (this.item.instituicaoSelecionada == "" &&
          checkUserHaveRole([
            constantes.ROLE_SUPER_ADMIN,
            constantes.ROLE_CLIENTE_ADMIN,
          ])) ||
        !this.item.email ||
        !this.item.telefone ||
        (!this.item.senha && this.index < 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();

    if (
      checkUserHaveRole([
        constantes.ROLE_SUPER_ADMIN,
        constantes.ROLE_CLIENTE_ADMIN,
      ])
    ) {
      this.loadInstituicoes();
    } else {
      this.showInstituicao = false;
      this.item.instituicaoSelecionada =
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO;
      this.onChangeInstituicao();
    }
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    trashRoute() {
      this.$router.push("alunosTrash");
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.item = {};
      this.index = -1;
    },
    salvar() {
      this.saveServer();
    },
    deletar(index) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção do aluno(a)?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$root.$emit("Spinner::show");

            this.$api.put("alunos/" + index + "/trash", {}).then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Aluno(a) removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function () {
                location.reload();
              }, 1000);
              // this.loadData();
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // this.items.splice(index, 1);
    },
    editar(index) {
      this.edit = true;
      this.index = index;
      this.item = index;
      console.log(this.item);
      this.item.instituicaoSelecionada = this.item.turma.instituicao.id;
      this.item.anoSelecionado = this.item.turma.anoEnsino.id;
      this.showModalCreate = true;
      this.turmaSelecionadaEdit = this.item.turma;
      this.item.turmaSelecionada = this.item.turma.id;
      this.item.email = this.item.usuario.email;

      this.loadTurmas();
    },
    loadInstituicoes() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("instituicao_ensinos")
        .then((response) => {
          this.instituicoes = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      let alunosFiltrados = [];
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api.get("alunos").then((response) => {
        console.log(response.data);
        if (idInstituicao == 0) {
          this.items = response.data;
        } else {
          this.items = response.data.filter(aluno => 
            aluno.trashAt == null && 
            aluno.instituicao.id == idInstituicao &&
            aluno.turma.trashAt == null)
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      this.item.turma = this.item.turmaSelecionada;

      console.log(this.item);

      if (
        this.item.turma != undefined &&
        this.item.anoSelecionado != undefined
      ) {
        if (this.edit) {
          this.$api
            .put("alunos-edit/" + this.item.id, this.item)
            .then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Aluno alterado com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                setTimeout(function () {
                  location.reload();
                }, 1500);
                this.showModalCreate = false;
                this.edit = false;
              });
            })
            .catch((error) => {
              this.$root.$emit("Spinner::hide");

              if (error.response.status == 400) {
                Swal.fire({
                  icon: "error",
                  position: "center",
                  title:
                    '<span style="font-size: 20px;">' +
                    error.response.data.detail +
                    "</span>",
                  showConfirmButton: false,
                  timer: 3500,
                }).then((result) => {
                  setTimeout(function () {
                    location.reload();
                  }, 1500);
                  this.showModalCreate = false;
                });
              } else if (error.response.status == 401) {
                store.dispatch("auth/ActionSignOut");
                window._Vue.$router.push({ name: "login" });
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    '<span style="font-size: 20px;">' +
                    error.response.data.violations[0].message +
                    "</span>",
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            });
        } else {
          this.$api
            .post("alunos-add", this.item)
            .then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Aluno cadastrado com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                // this.loadData();
                setTimeout(function () {
                  location.reload();
                }, 1500);
                this.showModalCreate = false;
              });
            })
            .catch((error) => {
              this.$root.$emit("Spinner::hide");
              if (error.response.status == 400) {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    '<span style="font-size: 20px;">' +
                    error.response.data.detail +
                    "</span>",
                  showConfirmButton: false,
                  timer: 3500,
                }).then(() => {
                  // this.loadData();
                  setTimeout(function () {
                    location.reload();
                  }, 2500);
                  this.showModalCreate = false;
                });
              } else if (error.response.status == 401) {
                store.dispatch("auth/ActionSignOut");
                window._Vue.$router.push({ name: "login" });
              } else {
                // Swal.fire({
                //   position: "center",
                //   icon: 'error',
                //   title: '<span style="font-size: 20px;">' + error.response.data.violations[0].message + '</span>',
                //   showConfirmButton: false,
                //   timer: 2500
                // });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Aluno cadastrado com sucesso!",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  // this.loadData();
                  setTimeout(function () {
                    location.reload();
                  }, 1500);
                  this.showModalCreate = false;
                });
              }
            });
        }
      } else {
        this.$root.$emit("Spinner::hide");
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Existem informações incompletas",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");

      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anos.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    onChangeInstituicao() {
      this.loadAnos();
    },
    loadTurmas() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api
        .get("turma_ensinos")
        .then((response) => {
          this.$root.$emit("Spinner::hide");
          console.log(response);
          // response.data.forEach((res) => {
          //   if (res.trashAt == null) {
          //     this.turmas.push(res);
          //   }
          // });
          response.data.forEach((res) => {
            console.log(res);
            if (res.instituicao != null) {
              if (res.trashAt == null && res.instituicao.id == idInstituicao)
                this.turmas.push(res);
            }
          });

          if (this.index >= 0) {
            this.item.turmaSelecionada = this.turmaSelecionadaEdit;
          }
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 3500,
            });
          } else if (error.response.status == 401) {
            //
            // store.dispatch('auth/ActionSignOut')
            // window._Vue.$router.push({ name: 'login' })
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    onChangeAno() {
      this.turmas = []
      this.loadTurmas();
    },
    cancel() {
      this.showModalCreate = false;
      window.location.reload();
    },
  },
};
</script>

<style></style>
